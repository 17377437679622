// For some reason using miranda-react here yields an error
// "NotSupportedError: This name has already been registered in the registry.", only in test.
// So I'm using loadsmart-ui instead just to unblock the task
import { Layout, Spinner } from '@loadsmart/loadsmart-ui'

export default function GlobalSpinner() {
  return (
    <Layout.Stack
      justify="center"
      align="center"
      style={{
        height: '100vh',
      }}
      data-testid="global-spinner"
    >
      <Spinner size={48} />
    </Layout.Stack>
  )
}
